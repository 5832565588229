// // eslint-disable-next-line
// import React from "react";
// import logo from "../images/logo.png";
// import linkdin from "../images/linkedin-512.png";
// import mail from "../images/envelope-closed-512.png";
// import insta from "../images/instagram.png";
// import tel from "../images/telegram (1).png";
// import "./style.css";
// export default function Footer() {
//   return (
//     <footer className="footer text-lg-start">
//       <div className="container-fluid ms-lg-3 center">
//         <div className="row-lg Footer-Main-Container justify-content-lg-evenly justify-content-sm-center">
//           {/* ---------------------left-section-footer-------------- */}
//           <div className="left-footer my-2">
//             <div className="d-flex d-inline flex-row">
//               <div className="">
//                 <img src={logo} alt="logo" className="logo text-sm-center" />
//               </div>
//               <div className="my-2">
//                 <h4 className="footer-name text-white text-md-start text-sm-center">
//                   <strong>SELLULAR</strong>
//                 </h4>
//               </div>
//             </div>
//             <div className="col-sm-12 col-md-7">
//               <p className="footer-text text-start text-white">
//                 Sellular is the most trusted way of buying and selling around
//                 your campus. Choose from fully verified and trusted people to
//                 buy and sell.
//               </p>
//             </div>
//             {/* <!-- Load icon library --> */}
//             {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/> */}

//             {/* <!-- The form --> */}
//             <a
//               className="contact-us-Button"
//               href="mailto:help.sellular@gmail.com"
//             >
//               Contact Us
//             </a>
//             {/* <form className="example" action="action_page.php">
//               <input type="text" placeholder="Your email address" name="search"/>
//                 <button className='buttonSubmitS' type="submit" ><i class="fa-solid fa-paper-plane"></i></button>
//             </form> */}
//           </div>
//           {/*------------------ Right-section footer-------------- */}
//           <div
//             className="right-footer my-4 "
//             style={{ paddingLeft: "33px", paddingTop: "15px" }}
//           >
//             <div className="right-footer-container ">
//               <div className="container mx-2">
//                 <li className="text-white">
//                   <a href="/career" className="fs ">
//                     Career
//                   </a>
//                 </li>
//                 <li className="text-white">
//                   <a href="#Features" className="fs ">
//                     Features
//                   </a>
//                 </li>
//                 <li className="text-white">
//                   <a href="#FaqSection" className="fs ">
//                     FAQ
//                   </a>
//                 </li>
//                 <li className="text-white">
//                   <a href="https://discord.gg/tW9gxHyQ" className="fs ">
//                     Join Us
//                   </a>
//                 </li>
//               </div>
//               <div className="container newContainer mx-2">
//                 <li className="text-white">
//                   <a href="/PrivacyPolicy" className="fs ">
//                     Privacy Policy
//                   </a>
//                 </li>
//                 <li className="text-white">
//                   <a href="/CancellationRefund" className="fs ">
//                     Cancellation/Refund Policy
//                   </a>
//                 </li>
//                 <li className="text-white">
//                   <a href="/TermsAndCondition" className="fs ">
//                     Terms & Condition
//                   </a>
//                 </li>
//                 <li className="text-white">
//                   <a href="https://discord.gg/tW9gxHyQ" className="fs ">
//                     About Us
//                   </a>
//                 </li>
//               </div>
//             </div>
//             {/* --------------social media icons--------------  */}
//             <div className="container">
//               <div
//                 className="d-flex my-4"
//                 style={{ display: "flex", flexDirection: "row" }}
//               >
//                 <div className="d-flex ms-auto media-icon-box ">
//                   <a href="https://tr.ee/tbTI3J6tKC" target="_blank">
//                     <i class="fa-brands fa-linkedin-in "></i>
//                   </a>
//                 </div>
//                 <div className="d-flex media-icon-box">
//                   <a href="support@sellular.club">
//                     <i class="fa-solid fa-envelope"></i>
//                   </a>
//                 </div>
//                 <div className="d-flex me-5 media-icon-box">
//                   <a
//                     href="https://instagram.com/sellular_networks?igshid=YmMyMTA2M2Y="
//                     target="_blank"
//                   >
//                     <i class="fa-brands fa-instagram "></i>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="text-center p-4">
//         <a id="footer-ending">© 2022 Sellular All Rights Reserved.</a>
//       </div>
//     </footer>
//   );
// }

function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer-img">
        <a
          href="http://sellular.club"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="main-logo.png" alt="sellular-logo" />
        </a>
        <h2>
          <a
            href="mailto:help.sellular@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-envelope"></i> help.sellular@gmail.com
          </a>
        </h2>
      </div>
      <div className="vertical-line"></div>

      <div className="links">
        <ul className="footer-links">
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club/PrivacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club/TermsAndCondition"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club"
              target="_blank"
              rel="noreferrer"
            >
              About Us
            </a>
          </li>
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club/CancellationRefund"
              target="_blank"
              rel="noreferrer"
            >
              Refund Policy
            </a>
          </li>
        </ul>
        <ul className="social-icons">
          <li className="social-icons-list">
            <a
              className="discord social-icons-list-a"
              href="https://discord.gg/fsDVF6sy"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-discord"></i>
            </a>
          </li>
          <li>
            <a
              className="twitter social-icons-list-a"
              href="/#"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              className="instagram social-icons-list-a"
              href="https://www.instagram.com/sellular_networks/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              className="linkedin social-icons-list-a"
              href="https://www.linkedin.com/company/sellularnetworks/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
