import React from 'react';
import'./style.css';

export default function Banner2() {
  return (
    <>
    <div className="bannerSection">
    <div className="banner" id='banner-2'>
      {/* right-circle */}
            <div className="cir">
            <svg viewBox="0 0 600 600" className="c1">
                <g transform="translate(300,300)"><path d="M153.6,-239C199.1,-209.8,236,-167.2,258.4,-118C280.9,-68.9,288.9,-13.1,281.2,40.4C273.5,93.9,250.1,145.2,214.7,186.1C179.3,226.9,131.9,257.4,80,272.6C28.2,287.8,-28.2,287.8,-80,272.6C-131.9,257.4,-179.3,226.9,-214.7,186.1C-250.1,145.2,-273.5,93.9,-281.2,40.4C-288.9,-13.1,-280.9,-68.9,-258.4,-118C-236,-167.2,-199.1,-209.8,-153.6,-239C-108.1,-268.3,-54.1,-284.1,0,-284.1C54.1,-284.1,108.1,-268.3,153.6,-239Z" fill="currentColor"></path></g>
            </svg>
                  {/* text-Container */}
            <div className="bannner-con container"style={{marginTop:'40px',display:'flex',alignContent:'center',justifyContent:'center'}} >            
                <div className="row">
                <div className="banner-text  col-lg-10 col-md-10" >
                  <h1 style={{fontWeight:'700',color:'rgba(36,76,94)'}}><strong>Join Our Discord Server</strong></h1>
                </div>    
                  <div className="banner-btn  col-lg-2  col-md-2 ">
                      <a href='https://discord.gg/pmznCaHE' className='button-discord' target="_blank">Join</a>
                  </div>
                </div>
            </div>
                <div className="row justify-content-center pt-4">
                <div className="banner-text text2 col-lg-6 col-md-10 text-center" >
                  <h1 style={{fontWeight:'700',color:'rgba(0,76,94)'}}><strong>Want Sellular in Your College?</strong></h1>
                </div>    
                  <div className="banner-btn  col-lg-2  col-md-2 ">
                      <a href='https://forms.gle/GqKKm4ejSL22rFj68' target="_blank" className='button-discord '>Click Here</a>
                  </div>
                </div>
            {/* left-circle */}
            <svg viewBox="0 0 600 600" className="c2">
                <g transform="translate(300,300)"><path d="M153.6,-239C199.1,-209.8,236,-167.2,258.4,-118C280.9,-68.9,288.9,-13.1,281.2,40.4C273.5,93.9,250.1,145.2,214.7,186.1C179.3,226.9,131.9,257.4,80,272.6C28.2,287.8,-28.2,287.8,-80,272.6C-131.9,257.4,-179.3,226.9,-214.7,186.1C-250.1,145.2,-273.5,93.9,-281.2,40.4C-288.9,-13.1,-280.9,-68.9,-258.4,-118C-236,-167.2,-199.1,-209.8,-153.6,-239C-108.1,-268.3,-54.1,-284.1,0,-284.1C54.1,-284.1,108.1,-268.3,153.6,-239Z" fill="currentColor"></path></g>
            </svg>
        </div>   
        
    </div>
    </div>
    
    </>
  
    
  )
}
