import React, { useEffect } from "react";
import SupportIconImage from "../images/chat 4.png";
import ShieldIconImage from "../images/secure-payment.png";
import CustomizeIconImage from "../images/filter 4.png";
import FastIconImage from "../images/thunder.png";
import ReliableIconImage from "../images/motivation 4.png";
import SimpleIconImage from "../images/click 2.png";
import Reverse from "../images/reverse.png";
import Aos from "aos";
import "aos/dist/aos.css";
// import CardsBackgroundImage from "../images/cards-background.webp";
import "./style.css";
<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor"
  crossorigin="anonymous"
></link>;

const cards = [
  {
    imageSrc: ShieldIconImage,
    title: "Secure",
    description:"We specifically deal with the consigners to provide top notch security.",
    key: 1,
  },
  {
    imageSrc: SupportIconImage,
    title: "Chat",
    description:"We bestow chat system to prosper the connections between the seller and the buyer.",
    key: 2,
  },
  {
    imageSrc: CustomizeIconImage,
    title: "Customizable",
    description: "Consigners have full flexibility to sell items of any category.",
    key: 3,
  },
  {
    imageSrc: ReliableIconImage,
    title: "Reliable",
    description:"Sellular is the most reliable platform trusted by each and every student.",
    key: 4,
  },
  {
    imageSrc: FastIconImage,
    title: "Fast",
    description: "Sellular is an expeditious abd dedicated forum without any respite.",
    key: 5,
  },
  {
    imageSrc: SimpleIconImage,
    title: "Easy",
    description:
      "Sellular is easily accessible and user friendly.",
    key: 6,
  },
  {
    imageSrc: ShieldIconImage,
    title: "Secure",
    description:"We specifically deal with the consigners to provide top notch security.",
    key: 7,
  },
  {
    imageSrc: SupportIconImage,
    title: "Chat",
    description:"We bestow chat system to prosper the connections between the seller and the buyer",
    key: 8,
  }
];

function Cards() {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[]);
  let text1 ="fade-right";
  let text2 ="fade-left";
  return (
    <section id="Features">
    {/* // this div holds the complete card section  */}
    <div className="mainContainerCards">
      {/* this the div which renders the heading  */}
      <div className="">
        <h1 className="heading">
          Amazing <span className="feat">Features</span>
        </h1>
      </div>

      {/* Cards for tab and desktop view */}
    
      <div className="cardsContainer">
      {cards.map((card) => (
        <div className="cards" key={card.key}  data-aos={(card.key==1||card.key==2||card.key==5||card.key==6)?text1:text2}>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img className="card-img" src={card.imageSrc} alt="" />
                <h4 className="card-title"> {card.title} </h4>
              </div>
              <div className="flip-card-back">
                <img className="card-back-img" src={card.imageSrc} alt="" />
                <p className="card-description"> {card.description} </p>
              </div>
            </div>
          </div>

        </div>
      ))}
      </div>

      {/* Carousel for mobile view */}
      {/* <div className="carouselContainer">
        {cards.map((card) => (
          <div className="carousel-cards">
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img className="card-img" src={card.imageSrc} alt="" />
                  <h4 className="card-title"> {card.title} </h4>
                  <img src={Reverse} width="15px" height="15px" />
                </div>
                <div className="flip-card-back">
                  <p className="card-description"> {card.description} </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        
      </div> */}
      <div className="carouselContainer">
      <div id="carouselExampleFade" className="carousel slide" data-bs-interval="false">
        <div className="carousel-inner">


          <div className="carousel-item active">
            <div className="carousel-cards">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img className="card-img" src={cards[0].imageSrc} alt="" />
                    <h4 className="card-title"> {cards[0].title} </h4>
                    <img src={Reverse} width="15px" height="15px" />
                  </div>
                  <div className="flip-card-back">
                    <img className="card-back-img" src={cards[0].imageSrc} alt="" />
                    <p className="card-description"> {cards[0].description} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-cards">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img className="card-img" src={cards[1].imageSrc} alt="" />
                    <h4 className="card-title"> {cards[1].title} </h4>
                    <img src={Reverse} width="15px" height="15px" />
                  </div>
                  <div className="flip-card-back">
                    <img className="card-back-img" src={cards[1].imageSrc} alt="" />
                    <p className="card-description"> {cards[1].description} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-cards">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img className="card-img" src={cards[2].imageSrc} alt="" />
                    <h4 className="card-title"> {cards[2].title} </h4>
                    <img src={Reverse} width="15px" height="15px" />
                  </div>
                  <div className="flip-card-back">
                    <img className="card-back-img" src={cards[2].imageSrc} alt="" />
                    <p className="card-description"> {cards[2].description} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-cards">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img className="card-img" src={cards[3].imageSrc} alt="" />
                    <h4 className="card-title"> {cards[3].title} </h4>
                    <img src={Reverse} width="15px" height="15px" />
                  </div>
                  <div className="flip-card-back">
                    <img className="card-back-img" src={cards[3].imageSrc} alt="" />
                    <p className="card-description"> {cards[3].description} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-cards">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img className="card-img" src={cards[4].imageSrc} alt="" />
                    <h4 className="card-title"> {cards[4].title} </h4>
                    <img src={Reverse} width="15px" height="15px" />
                  </div>
                  <div className="flip-card-back">
                    <img className="card-back-img" src={cards[4].imageSrc} alt="" />
                    <p className="card-description"> {cards[4].description} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-cards">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img className="card-img" src={cards[5].imageSrc} alt="" />
                    <h4 className="card-title"> {cards[5].title} </h4>
                    <img src={Reverse} width="15px" height="15px" />
                  </div>
                  <div className="flip-card-back">
                    <img className="card-back-img" src={cards[5].imageSrc} alt="" />
                    <p className="card-description"> {cards[5].description} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          {/* <span className="visually-hidden">Previous</span> */}
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          {/* <span className="visually-hidden">Next</span> */}
        </button>
        </div>
      </div>
    </div>  
    </section >
  );
}

export default Cards;