// this file renders all the components

import React from "react";
import Navbar from "./Navbar2.js";
import Banner from "./Banner";
import Cards from "./Cards";
import Faq from "./Faq.js";
import Banner2 from "./Banner2";
import About from "./About";

export default function Home() {
  return (
    <>
      <div className="body">
        <Navbar />
        <About />
        <Banner />
        <Cards />
        <Faq />
        <Banner2 />
      </div>
    </>
  );
}
