import React from 'react'
import './style.css'
const PrivacyPolicy = () => {
    return (
        <>
            <div className='Privcy-container'>
                <h1>
                    <strong>
                        Privacy Policy of our company
                    </strong>
                </h1>

                <h2>
                    Your data is safe with us
                </h2>
                <p>
                    Your privacy is of utmost importance and so we have made sure that all your data is safely stored with us. We have taken all the necessary steps to protect your data from any unauthorized access and use. We have not just limited it to your data but have also made sure that all your communications and interactions on our platform are secured. Moreover, we let you choose what data you wish to share with us and what not.</p><p><br /></p><h2>We don't share your private data with others</h2><p>Your privacy is of utmost importance to us and so we do not share your private data with anyone. We value your trust and would like to keep it that way. We have not made any agreements with any third-party company to share your private data.
                </p>
                <p>
                    <br />
                </p>
                <h2>
                    We use your data to improve your experience on our platform
                </h2>
                <p>We have made an effort to collect as much of your data as possible. We want to make your experience on our platform as smooth and flawless as possible. For this, we have a team of experts who make sure your experience is seamless and completely hassle-free. We have made a detailed report of the data you have provided to us and how we use it.
                </p>
                <p>
                    <br />
                </p>
                <h2>We value your privacy and do not want to harm or interfere with it in any way
                </h2>
                <p>
                    We have made sure that your privacy is safe with us. We have made a detailed report of the data you have provided to us and how we use it. We have tried to be as transparent as possible and have let you know what data we collect from you and why we collect it. Moreover, we understand the importance of privacy and would never take it for granted. We are not interested in collecting your data for malicious purposes or for any unethical or illegal activity. Your privacy is important for us and we value it.</p><p><br /></p><h2>In short</h2><p>We assure you that all your data is safe with us and is only being used to serve your needs better and to improve your overall experience on our platform. We value your privacy and do not want to harm or interfere with it in any way. We understand the importance of privacy and would never take it for granted.</p>
            </div>
        </>
    )
}

export default PrivacyPolicy;