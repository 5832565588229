import React, { useEffect } from "react";
import'./style.css';
import Aos from "aos";
import "aos/dist/aos.css";

export default function Banner(props) {
  useEffect(()=>{
    Aos.init({duration:1000});
  },[]);
  let text1 ="slide-left";
  let text2 ="slide-right";
  return (
    <>
    <div className='Bannner-con'>
    <div data-aos={text1} >
<div class="m-scroll" >
  <div class="m-scroll__title" >
    <div className='text-scroll'>
      <div className='LS'>
        <a  className='innnerText'>Sellular</a> 
        <a  className='innnerText'>Networks</a> 
        <a  className='innnerText'>Sell</a> 
        <a  className='innnerText'>Events </a> 
        <a  className='innnerText'> Internships </a>
         <a  className='innnerText'>Buy</a>
      </div>
      <div className='LS'>
      <a  className='innnerText'>Sellular</a> 
        <a className='innnerText'>Networks</a> 
        <a className='innnerText'>Sell</a> 
        <a  className='innnerText'>Events </a>
        <a  className='innnerText'> Internships </a>
         <a  className='innnerText'>Buy</a>
      </div>
      <div className='LS'>
      <a  className='innnerText'>Sellular</a> 
        <a className='innnerText'>Networks</a> 
        <a className='innnerText'>Sell</a> 
        <a  className='innnerText'>Events </a>
        <a  className='innnerText'> Internships </a>
         <a  className='innnerText'>Buy</a>
      </div>
      <div className='LS'>
      <a  className='innnerText'>Sellular</a> 
        <a className='innnerText'>Networks</a> 
        <a className='innnerText'>Sell</a> 
        <a  className='innnerText'>Events </a>
        <a  className='innnerText'> Internships </a>
         <a  className='innnerText'>Buy</a>
      </div>
    </div>
  </div>
</div>
</div>
{/* Second Banner */}
<div data-aos={text2} >
<div class="m-scroll-2 " style={{marginTop:'30px'}}>
  <div class="m-scroll__title-2">
    <div className='text-scroll-2'>
      <h1 className='LS'>
      <a  className='innnerText-2'>Launching</a> 
        <a className='innnerText-2'>App</a> 
        <a className='innnerText-2'>Clubs</a> 
        <a  className='innnerText-2'>Socities</a>
      </h1>
      <h1 className='LS rep'>
      <a  className='innnerText-2'>Launching</a> 
        <a className='innnerText-2'>App</a> 
        <a className='innnerText-2'>Clubs</a> 
        <a  className='innnerText-2'>Socities</a>
      </h1>
      <h1 className='LS rep'>
      <a  className='innnerText-2'>Launching</a> 
        <a className='innnerText-2'>App</a> 
        <a className='innnerText-2'>Clubs</a> 
        <a  className='innnerText-2'>Socities</a>
      </h1>
      <h1 className='LS rep'>
      <a  className='innnerText-2'>Launching</a> 
        <a className='innnerText-2'>App</a> 
        <a className='innnerText-2'>Clubs</a> 
        <a  className='innnerText-2'>Socities</a>
      </h1>
    </div>
  </div>
</div>
</div> 
</div>
</> 
   
  )}
        

















//     <div className="banner" >
//     <div style={{textAlign:'center'}}>
//         <h1 className='text display-3' style={{fontWeight:'700',paddingBottom:'100px'}}><strong>Launching Soon..!!</strong></h1>
//     </div>
//     <div className="cir">
//         <svg viewBox="0 0 600 600" className="c1">
//             <g transform="translate(300,300)"><path d="M153.6,-239C199.1,-209.8,236,-167.2,258.4,-118C280.9,-68.9,288.9,-13.1,281.2,40.4C273.5,93.9,250.1,145.2,214.7,186.1C179.3,226.9,131.9,257.4,80,272.6C28.2,287.8,-28.2,287.8,-80,272.6C-131.9,257.4,-179.3,226.9,-214.7,186.1C-250.1,145.2,-273.5,93.9,-281.2,40.4C-288.9,-13.1,-280.9,-68.9,-258.4,-118C-236,-167.2,-199.1,-209.8,-153.6,-239C-108.1,-268.3,-54.1,-284.1,0,-284.1C54.1,-284.1,108.1,-268.3,153.6,-239Z" fill="currentColor"></path></g>
//         </svg>
    
//         <svg viewBox="0 0 600 600" className="c2">
//             <g transform="translate(300,300)"><path d="M153.6,-239C199.1,-209.8,236,-167.2,258.4,-118C280.9,-68.9,288.9,-13.1,281.2,40.4C273.5,93.9,250.1,145.2,214.7,186.1C179.3,226.9,131.9,257.4,80,272.6C28.2,287.8,-28.2,287.8,-80,272.6C-131.9,257.4,-179.3,226.9,-214.7,186.1C-250.1,145.2,-273.5,93.9,-281.2,40.4C-288.9,-13.1,-280.9,-68.9,-258.4,-118C-236,-167.2,-199.1,-209.8,-153.6,-239C-108.1,-268.3,-54.1,-284.1,0,-284.1C54.1,-284.1,108.1,-268.3,153.6,-239Z" fill="currentColor"></path></g>
//         </svg>
//     </div>
// </div>