import React from 'react'

const CancellationRefund = () => {
    return (
        <div className='Cancellation-Refund'>
            <h1>Cancellation/Refund Policy</h1>
            <h3>Refund:</h3>
            <p>
                You are entitled to claim your refund within 30 days without giving any reason for doing so.
                The deadline for claiming refund is 30 days from the date you paid.
                In order to exercise your right of cancellation, you must inform us of your decision by means of a clear statement.
                You can inform us of your decision by e-mail hello.sellular@gmail.com
                We will reimburse you no later than 3 working days from the day on which we receive the refund request. We will use the same means of payment as you used for the order, and you will not incur any fees for such reimbursement.
            </p>
        </div>
    )
}

export default CancellationRefund