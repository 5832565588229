import React, { useEffect } from "react";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

// this function makes the hiding and unhiding of the answer text 

export default function Faq() 
{
  useEffect(()=>{
    Aos.init();
  },[]);
  let text2 ="fade-up";
  return(
    <section id="FaqSection">
      <div className="Faqcontainer">
          <div className="faqBox-1 faq_steps" data-aos={text2} data-aos-duration="1000" data-aos-anchor-placement=".other-element">
            <h3 className='headingFaq'>What is Sellular?</h3> 
            <p className='faqText'>Sellular is a one stop solution for Students</p></div>
          <div className="faqBox-2 faq_steps" data-aos={text2}data-aos-duration="1500" data-aos-anchor-placement=".other-element">
            <h3 className='headingFaq'>How to Create Account of Sellular?</h3> 
            <p className='faqText'>Downlaod our App -> Fill Out All Your Details -> Click On Create Account</p>
          </div>
          <div className="faqBox-3 faq_steps" data-aos={text2}data-aos-duration="2500" data-aos-anchor-placement=".other-element">
            <h3 className='headingFaq'>How To Buy a Product?</h3> 
            <p className='faqText'>Open a Product that You need to Buy -> Click on The "Chat" Option -> Chat With the Seller to buy the Product</p>
          </div>
          <div className="faqBox-4 faq_steps" data-aos={text2} data-aos-duration="3000" data-aos-anchor-placement=".other-element">
            <h3 className='headingFaq'>Who can add Products and How?</h3> 
            <p className='faqText'>Products can be added by any member of a desired college. To Add Product - Go To "Add Products" Section -> Fill out all the Desired Details of product that needs to be added -> Click on "Add Product" Button.</p>
          </div>
      </div>
    </section>
  )
}
