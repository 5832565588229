import React from "react";
import { useRef } from "react";
import logo from "../images/logo.png";

export default function Navbar2() {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <div>
      <header>
        <div className="logoText d-flex d-inline flex-row align-content-center">
          {/* this div contains the logo of the company at the navbar  */}
          <div className="">
            <a href="./">
              <img src={logo} alt="logo" className="Logo-navbar" />
            </a>
          </div>
          {/* this is the div containing the company name in the navbar  */}
          <div className="align-content-center">
            <a href="/" style={{ textDecoration: "none" }}>
              <h4 className="logo-text">
                <strong>SELLULAR</strong>
              </h4>
            </a>
          </div>
        </div>
        <nav ref={navRef}>
          <div className="nav-links">
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <i class="fa-solid fa-xmark"></i>
            </button>
            <a
              className="text-white nav-link mt-2"
              href="#Features"
              onClick={showNavbar}
            >
              Features
            </a>
            <a
              className="text-white nav-link mt-2"
              href="https://career.sellular.club"
              onClick={showNavbar}
            >
              Careers
            </a>
            <a
              className="text-white nav-link mt-2"
              href="https://placex.sellular.club"
              target="_blank"
            >
              PlaceX
            </a>
            <a
              className="text-white nav-link mt-2"
              href="#FaqSection"
              onClick={showNavbar}
            >
              FAQ
            </a>
          </div>
        </nav>
        <div className="outNav justify-content-end pe-2">
          <a
            href="https://discord.gg/tW9gxHyQ"
            className="btn-discord btn-out"
            target="_blank"
          >
            <span>Join</span>
          </a>
          <button className="nav-btn nav-ham-btn" onClick={showNavbar}>
            <i class="fa-solid fa-bars"></i>
          </button>
        </div>
      </header>
    </div>
  );
}
