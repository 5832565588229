import React, { useEffect } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import img from "../images/Asset 1.png";
import icon from "../images/check.png";
import Aos from "aos";
import "aos/dist/aos.css";
export default function About() {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[]);
  let text1 ="fade-right";
  let text2 ="fade-left";
  return (
    <div className="mainContainer">
    <div className="Container" data-aos={text1}>
      <div className="nameContainer">
        <h3 className="headingText">Sell</h3>
        <div className="typewriter-text">
          <TypeWriterEffect
            textStyle={{
              paddingTop:"12px",
              fontFamily: "arial",
              color: "#1EBAA3",
              fontWeight: "bolder",
              fontSize: "3em",
              hideCursorAfterText: true,
            }}
            startDelay={2000}
            cursorColor="#1EBAA3"
            multiText={["ಮಾಡು", "செய்", "കറോ", "ਕਰੋ", "करो", "ular"]}
            multiTextDelay={2000}
            typeSpeed={100}
            loop={true}
          />
        </div>
      </div>
      <div className="descriptionContainer">
        <p className="descriptionText">
          Sellular is the most trusted way of buying and selling around your
          campus. Choose from fully verified and trusted people to buy and sell.
          Choose online and chat to know more. Sellular Assured®
        </p>
        <div className="featureArea">
          <img src={icon} className="icon" />
          <li className="featureText">All Verified Profiles</li>
        </div>
        <div className="featureArea">
          <img src={icon} className="icon" />
          <li className="featureText">Buy what you Need</li>
        </div>
        <div className="featureArea">
          <img src={icon} className="icon" />
          <li className="featureText">Premium Services at No cost</li>
        </div>
      </div>
    </div>
      <div className="imageContainer" data-aos={text2}>
        <img className="mainImage" src={img} alt="img" />
      </div>
      </div>
  );
}
