import "./App.css";
import Career from "./components/Career.js";
import Footer from "./components/Footer.js";
import Home from "./components/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Navbar  from "./components/Navbar2";
import TermsCondition from "./components/TermsCondition";
import CancellationRefund from "./components/CancellationRefund";
function App() {
  return (
    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
      </Routes>
      <Routes>
        <Route path="/TermsAndCondition" element={<TermsCondition/>} />
      </Routes>
      <Routes>
        <Route path="/CancellationRefund" element={<CancellationRefund/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
